body,
#root,
div[data-amplify-theme='default-theme'] {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	margin: 0;
	padding: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	overflow: hidden;
	height: 100%;
}

:root {
	--amplify-secondary-color: #212121;
	--amplify-secondary-tint: #616161;
	--amplify-secondary-shade: #424242;
	--amplify-primary-color: #b71c1c;
	--amplify-primary-tint: #e53935;
	--amplify-primary-shade: #d50000;
}

.loginContainer {
	height: 100%;
	overflow-y: auto;
}

header.authenticate {
	background-color: #252527;
	text-align: center;
	padding: 1rem 0;
	color: #fff;
}
header.authenticate img {
	width: 100px;
	height: 100px;
}

header.authenticate p {
	font-size: 1.5rem;
}

header.authenticate p span {
	white-space: nowrap;
}

amplify-authenticator {
	--container-height: initial;
	--container-justify: center;
	--width: 500px;
	--border-radius: 0;
}

.flex {
	flex-grow: 1;
}

@media print {
	.hide-for-printing {
		display: none !important;
	}
}

@media screen and (max-width: 675px) {
	header.authenticate img {
		width: 80px;
		height: 80px;
	}
	header.authenticate p {
		font-size: 1rem;
	}
	header.authenticate h1 {
		font-size: 1.5em;
	}
}

@media screen and (max-width: 400px) {
	header.authenticate img {
		width: 60px;
		height: 60px;
	}
	header.authenticate p {
		font-size: 0.75rem;
	}
	header.authenticate h1 {
		font-size: 1em;
	}
}
